<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="storecostdays" ref="dt" v-model:selection="selectedRow" :metaKeySelection="false"
                    v-model:filters="filters1" filterDisplay="menu" dataKey="id" :tableStyle="styleHeight"
                    :scrollHeight="sHeight" :scrollable="true" responsiveLayout="scroll" :loading="loading"
                    :paginator="false" :lazy="false" class="p-mt-3">
                    <template #header>
                        <div class="grid">
                            <div class="col-8">
                                <div class="text-left">
                                    <Calendar placeholder="统计日期" v-model="storeTime" dateFormat="yy-mm-dd" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="text-right">
                                    <Button type="button" label="查询" class="mb-2"
                                        @click="customFilter();" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button type="button" icon="pi pi-external-link" label="导出" @click="exportData()"
                                        class="p-button-outlined mb-2" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button type="button" icon="pi pi-filter-slash" label="Clear"
                                        class="p-button-outlined mb-2" @click="clearFilter1()" />
                                </div>
                            </div>
                        </div>
                    </template>
                    <Column field="mpn" :footer="getTableFooter('mpn')" :showFilterOperator="true" header="MPN" frozen
                        :filterMatchModeOptions="matchModes" :sortable="false">
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="itemcode" :showFilterOperator="true" header="Item code" :sortable="false"
                        :filterMatchModeOptions="matchModes" frozen>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="day30" :footer="getTableFooter('day30')" header="30天内" :sortable="true">
                    </Column>
                    <Column field="day60" :footer="getTableFooter('day60')" header="60天内" :sortable="true">
                    </Column>
                    <Column field="day90" :footer="getTableFooter('day90')" header="90天内" :sortable="true">
                    </Column>
                    <Column field="day120" :footer="getTableFooter('day120')" header="120天内" :sortable="true">
                    </Column>
                    <Column field="day150" :footer="getTableFooter('day150')" header="150天内" :sortable="true">
                    </Column>
                    <Column field="day180" :footer="getTableFooter('day180')" header="180天内" :sortable="true">
                    </Column>
                    <Column field="day210" :footer="getTableFooter('day210')" header="210天内" :sortable="true">
                    </Column>
                    <Column field="day240" :footer="getTableFooter('day240')" header="240天内" :sortable="true">
                    </Column>
                    <Column field="day270" :footer="getTableFooter('day270')" header="270天内" :sortable="true">
                    </Column>
                    <Column field="daymore" :footer="getTableFooter('daymore')" header="＞270天" :sortable="true">
                    </Column>
                    <Column field="cost" :footer="getTableFooter('cost')" header="当日费用($)" :sortable="true">
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
</template>
<script>
    import {
        ref,
    } from 'vue';
    import {
        FilterMatchMode,
        FilterOperator
    } from 'primevue/api';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import commonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '760';
            return {
                confirm,
                currentMenuId,
            };
        },
        data() {
            return {
                matchModes: [],
                timeMatchModes: [],
                numMatchModes: [],
                butLoading: false,
                position: 'bottomleft',
                filters1: null,
                selectedRow: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                listTitle: '仓储日统计',
                selectedKey: {
                    760: true
                },
                expandedKey: null,
                storeTime: '',
                storecostdays: [],
                loading: false,
                nodes: [],
                items: [{
                    label: '统计操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                        label: '导出',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => {
                            this.exportData();
                        }
                    }, ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
            }
        },
        created() {
            MessageTip.initMsg();
            this.storeTime = commonJs.GetCurrentDate();
            this.initFilters1();
            this.loadMenuTree();
            this.sHeight = (window.innerHeight - 280) + "px";
            this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
            this.minHeight = "height:" + (window.innerHeight - 132) + "px";
        },
        methods: {
            initFilters1() {
                this.timeMatchModes = [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ];
                this.numMatchModes = [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ];
                this.matchModes = [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ];
                this.filters1 = {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    'mpn': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'itemcode': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    }
                }
            },
            clearFilter1() {
                this.storeTime = '';
                this.initFilters1();
            },
            loadMenuTree() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '731',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usertreemenulist(where:" + JSON.stringify(listwhere) +
                    "){key label data nodetype icon leaf children {key label data nodetype parentid parenturl icon leaf} }}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.nodes = jsonData.data.usertreemenulist;
                        if (this.$route.query.nodeid && this.$route.query.nodeid != '' && this.nodes) {
                            this.selectedKey = {};
                            this.selectedKey[this.$route.query.nodeid] = true;
                            this.expandedKey = {};
                            this.expandedKey[this.currentMenuId] = true;
                            var isFind = false;
                            for (var i = 0; i < this.nodes.length; i++) {
                                for (var j = 0; j < this.nodes[i].children.length; j++) {
                                    if (this.nodes[i].children[j].key == this.$route.query.nodeid) {
                                        isFind = true;
                                        this.queryPlanObj = {
                                            name: this.nodes[i].children[j].label,
                                            id: this.nodes[i].children[j].key,
                                            parentid: this.currentMenuId,
                                            queryconfig: this.nodes[i].children[j].data,
                                        };
                                        if (this.queryPlanObj.queryconfig && this.queryPlanObj.queryconfig !=
                                            '') {
                                            this.filters1 = JSON.parse(this.queryPlanObj.queryconfig);
                                        }
                                        this.listTitle = this.nodes[i].children[j].label;
                                        break;
                                    }
                                }
                                if (isFind) {
                                    break;
                                }
                            }
                        }
                        this.loadData(1);
                    }
                });
            },
            onNodeSelect(node) {
                console.log(node);
                setTimeout(() => {
                    var currentKey = '';
                    if (this.selectedKey) {
                        for (var key in this.selectedKey) {
                            currentKey = key;
                            break;
                        }
                    }
                    if (node.nodetype == '1') {
                        this.queryPlanObj = {
                            name: '',
                            id: '-1',
                            parentid: this.currentMenuId,
                            queryconfig: '',
                        };
                        console.log('node.key:' + node.key);
                        console.log('selectedKey:');
                        console.log(this.selectedKey);
                        console.log('currentKey:' + currentKey);
                        if (node.key != this.currentMenuId) {
                            window.location.href = node.data;
                        } else {
                            this.clearFilter1();
                            this.clearFilter2();
                            this.loadData(1);
                            this.listTitle = "仓储日统计";
                        }
                    }
                    if (node.nodetype == '2') {
                        if (this.currentMenuId != node.parentid) {
                            window.location.href = node.parenturl + "?nodeid=" + node.key;
                        } else {
                            this.queryPlanObj = {
                                name: node.label,
                                id: node.key,
                                parentid: this.currentMenuId,
                                queryconfig: node.data,
                            };
                            console.log('expandedKey:');
                            console.log(this.expandedKey);
                            if (this.queryPlanObj.queryconfig && this.queryPlanObj.queryconfig != '') {
                                console.log('queryconfig:');
                                console.log(this.queryPlanObj.queryconfig);
                                this.filters1 = JSON.parse(this.queryPlanObj.queryconfig);
                                this.loadData(1);
                                this.listTitle = node.label;
                            }
                        }
                    }
                }, 20);
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            loadData(v_page) {
                if (this.storeTime == '') {
                    MessageTip.warnmsg('请选择统计日期');
                    return;
                }
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: -1,
                    conditions: [{
                        name: 'storetime',
                        value: this.storeTime,
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{storecostdaystatlist(where:" + JSON.stringify(listwhere) +
                    "){id storename mpn itemcode day30 day60 day120 day90 day150 day180 day210 day240 day270 daymore cost }}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log('loadData---');
                        console.log(jsonData);
                        this.storecostdays = jsonData.data.storecostdaystatlist;
                    }
                });
            },
            closeMaximizable() {},
            freshList() {
                this.butLoading = false;
                this.loadData(1);
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            exportData() {
                this.$refs.dt.exportCSV();
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            getTableFooter(v_field) {
                console.log('getTableFooter:');
                console.log(v_field);
                if (v_field == 'mpn') {
                    return "合计";
                }
                var numVal = 0;
                for (var i = 0; i < this.storecostdays.length; i++) {
                    var tempnum = Number(this.storecostdays[i][v_field]);
                    if (!isNaN(tempnum)) {
                        numVal = numVal + tempnum;
                    }
                }
                if (v_field == 'cost') {
                    numVal = commonJs.moneySwitch(numVal, 3)
                }
                return numVal;
            },
        },
        components: {
            'AppFooter': AppFooter,
        }
    }
</script>